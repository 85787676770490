import React from 'react'
import PropTypes from 'prop-types'
import { createLocaleTextGetter } from '../lib/localizor'

function localize(Component) {
  // Just return received Component with localized data as prop
  return props => {
    const getLocalizedContent = createLocaleTextGetter(props.pageContext.locale)
    return <Component {...props} data={getLocalizedContent(props.data)} />
  }
}
localize.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    locale: PropTypes.string
  })
}
export default localize
