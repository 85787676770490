import React, { useContext } from 'react'
import styles from './ProjectPreview.module.css'
import { AiOutlineCaretRight } from 'react-icons/ai'
import { GlobalStateContext, GlobalDispatchContext } from '../../containers/GlobalContextProvider'
import Img from 'gatsby-image'
import LinkTransition from '../navigation/LinkTransition'

function ProjectPreview(props) {
  const pageContext = useContext(GlobalStateContext)
  const projectLink =
    pageContext.locale === 'en'
      ? `/en/project/${props.slug.current}`
      : `/projekt/${props.slug.current}`

  const gatsbyImageStyle = {
    width: '100%',
    paddingBottom: '66%',
    height: 'auto'
  }

  const dispatch = useContext(GlobalDispatchContext)
  function projectSelected() {
    // Maybe animate project tiles up?
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
  }
  if (!props.listImage) return null

  return (
    <div className={styles.root}>
      <LinkTransition to={projectLink} label="" exitTrigger={projectSelected}>
        <div className={styles.wrap}>
          <div className={styles.leadMediaThumb}>
            {props.listImage && props.listImage.asset && (
              <Img
                fixed={props.listImage.asset.fixed}
                style={gatsbyImageStyle}
                width={600}
                quality={80}
              />
            )}
          </div>

          <h3 className={styles.title}>{props.title}</h3>
          <div className={styles.client}>{props.client}</div>
          <div className={styles.tagline}>
            <AiOutlineCaretRight />
            {props.tagline}
          </div>
        </div>
      </LinkTransition>
    </div>
  )
}

export default ProjectPreview
