import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

export default class WithPrevPageTrigger extends Component {
  static propTypes = {
    onShift: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props)
    this.wrapRef = React.createRef()
    this.virtualScrollInstance = null
    this.isTouch =
      typeof window !== 'undefined' ? window.matchMedia('(pointer: coarse)').matches : false
    this.shiftGap = this.isTouch ? 150 : 200
    this.state = {
      shifted: false
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      import('virtual-scroll').then(module => {
        const Scroller = module.default
        this.virtualScrollInstance = new Scroller({
          touchMultiplier: 2,
          passive: false
        })
        this.virtualScrollInstance.on(this.onVirtualScroll, this)
      })
      window.addEventListener('scroll', this.onScroll)
    }
  }

  componentWillUnmount() {
    this.setState({ shifted: false })
    window.removeEventListener('scroll', this.onScroll)
    if (this.virtualScrollInstance) this.virtualScrollInstance.destroy()
  }

  onScroll = () => {
    if (window.scrollY > 0 && this.state.shifted) {
      this.setState({ shifted: false })
      gsap.to(this.wrapRef.current, { y: 0, duration: 0.6 })
      this.props.onShift(false)
    }
  }

  onVirtualScroll(e) {
    const { deltaY } = e

    if (deltaY > 0 && window.scrollY === 0) {
      gsap.to(this.wrapRef.current, { y: this.shiftGap, duration: 0.6 })
      this.setState({ shifted: true })
      this.props.onShift(true)
    }
  }

  render() {
    return (
      <div ref={this.wrapRef} style={{ zIndex: 2, position: 'relative' }}>
        {this.props.children}
      </div>
    )
  }
}
