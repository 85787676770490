import React, { Component } from 'react'
import PropTypes from 'prop-types'
import gsap, { Power2 } from 'gsap'
import styles from './PrevPageTrigger.module.css'
import LinkTransition from './navigation/LinkTransition'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'
import { navigate } from 'gatsby'

export default class PrevPageTrigger extends Component {
  static contextType = GlobalDispatchContext

  static propTypes = {
    enable: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
    title: PropTypes.string,
    link: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.virtualScrollInstance = null
    this.transitionPlaneId = '#transition-plane'

    this.circleRef = React.createRef()

    this.virtualScrollEnabled = false
    this.triggered = false

    this.isTouch =
      typeof window !== 'undefined' ? window.matchMedia('(pointer: coarse)').matches : false

    const circleSize = this.isTouch ? 60 : 70

    this.overscrollMultiplier = this.isTouch ? 0.5 : 0.15
    this.state = {
      shown: false,
      circle: {
        r: circleSize,
        circumference: Math.PI * circleSize * 2
      }
    }

    this.currentDashOffset = this.state.circle.circumference
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      import('virtual-scroll').then(module => {
        const Scroller = module.default
        this.virtualScrollInstance = new Scroller({
          touchMultiplier: 4
        })
        this.virtualScrollInstance.on(this.onVirtualScroll, this)
      })
    }
  }

  componentWillUnmount() {
    if (this.virtualScrollInstance) this.virtualScrollInstance.destroy()
  }

  onVirtualScroll(e) {
    if (!this.props.enable || this.triggered) return false
    const { deltaY } = e
    if (deltaY >= 0) {
      this.currentDashOffset = this.currentDashOffset - deltaY * this.overscrollMultiplier

      if (this.currentDashOffset <= 0) {
        this.currentDashOffset = 0
        this.triggered = true
        this.completeCircle().then(() => {
          this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
          setTimeout(() => {
            navigate(this.props.link)
          }, 650)
        })
      } else {
        gsap.to(this.circleRef.current, {
          strokeDashoffset: this.currentDashOffset,
          duration: 0.4
        })
      }
    } else {
      // Reset circle
      gsap.to(this.circleRef.current, {
        strokeDashoffset: this.state.circle.circumference,
        duration: 0.8
      })
      this.currentDashOffset = this.state.circle.circumference
    }
  }

  completeCircle() {
    gsap.killTweensOf(this.circleRef.current)
    return new Promise((resolve, reject) => {
      gsap.to(this.circleRef.current, {
        strokeDashoffset: 0,
        duration: 0.2,
        onComplete() {
          resolve()
        }
      })
    })
  }

  onTrigger() {
    this.completeCircle().then(() => {
      this.context({ type: 'SET_TRANSITION_STATE', state: 'pageExiting' })
    })
  }

  render() {
    return (
      <div className={`${styles.root} ${this.props.show ? styles.show : ''}`}>
        <div className={styles.content}>
          <div className={styles.circle}>
            <svg height="150" width="150">
              <circle
                cx="75"
                cy="75"
                r={this.state.circle.r}
                stroke="var(--color-white)"
                strokeWidth="5"
                fillOpacity="0"
                ref={this.circleRef}
                strokeDasharray={this.state.circle.circumference}
                strokeDashoffset={this.state.circle.circumference}
              />
            </svg>
          </div>
          <div className={styles.pageLink}>
            <LinkTransition
              to={this.props.link}
              exitTrigger={this.onTrigger.bind(this)}
              exitDelay={1}
              entryDelay={1.3}
            >
              <>
                <span className={styles.triangleLeft}></span>
                <span>{this.props.title}</span>
              </>
            </LinkTransition>
          </div>
        </div>
      </div>
    )
  }
}
