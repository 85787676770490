import React from 'react'
import PropTypes from 'prop-types'
import styles from './ProjectListing.module.css'
import Container from './Container'
import ProjectPreviewGrid from './project/ProjectPreviewGrid'

function ProjectListing ({ projects }) {
  return (
    <section id='project-listing' className={styles.root}>
      <Container verticalPadding>
        {projects && projects.length > 0 && <ProjectPreviewGrid nodes={projects} />}
      </Container>
    </section>
  )
}

ProjectListing.propTypes = {
  projects: PropTypes.array.isRequired
}

export default ProjectListing
