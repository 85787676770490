import React from 'react'
import ProjectPreview from './ProjectPreview'

import styles from './ProjectPreviewGrid.module.css'

function ProjectPreviewGrid(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => {
            return node.listImage ? (
              <li key={node.id || node._id}>
                <ProjectPreview {...node} />
              </li>
            ) : null
          })}
      </ul>
    </div>
  )
}

ProjectPreviewGrid.defaultProps = {
  nodes: []
}

export default ProjectPreviewGrid
