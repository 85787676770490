import React, { useEffect, useContext, useState } from 'react'
import { graphql } from 'gatsby'
import gsap, { Power3 } from 'gsap'
import localize from '../containers/localize'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import PageHero from '../components/PageHero'
import NextSectionIndicator from '../components/NextSectionIndicator'
import ProjectListing from '../components/ProjectListing'
import Footer from '../components/Footer'
import WithPrevPageTrigger from '../containers/WithPrevPageTrigger'
import PrevPageTrigger from '../components/PrevPageTrigger'
import '../lib/prevent-pull-refresh'
export const query = graphql`
  query ProjectsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)projects/" }) {
      id
      title {
        _type
        sl
        en
      }
      description {
        _type
        sl
        en
      }
      heroImage {
        asset {
          _id
          fluid(maxWidth: 2800) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      shareImage {
        _type
        sl {
          asset {
            _id
          }
        }
        en {
          asset {
            _id
          }
        }
      }
      heroTitle {
        _type
        sl
        en
      }
      heroTekst {
        _type
        sl
        en
      }
    }
    projects: allSanityProject(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          _type
          id
          publishedAt
          listImage {
            asset {
              _id
              fixed(width: 600) {
                ...GatsbySanityImageFixed
              }
            }
          }
          title {
            _type
            sl
            en
          }
          client
          tagline {
            _type
            sl
            en
          }
          slug {
            _type
            sl {
              current
            }
            en {
              current
            }
          }
        }
      }
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      email
      phone
      street
      zipCode
      city
      country
      youtube
      facebook
      instagram
      linkedin
    }
  }
`

const ProjectsPage = props => {
  // Page is ready, when hero image and global fonts are loaded
  const { data, errors } = props

  if (errors) return <GraphQLErrorList errors={errors} />

  const page = data && data.page
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
    : []

  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE_CONTEXT_DATA',
      data: props.pageContext
    })
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
    }
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  let header = 'light'
  function onScroll() {
    if (window.scrollY > window.innerHeight) {
      if (header !== 'dark') {
        dispatch({ type: 'SET_HEADER_VARIATION', variation: 'darkGradient' })
        header = 'dark'
      }
    } else {
      if (header !== 'light') {
        dispatch({ type: 'SET_HEADER_VARIATION', variation: 'light' })
        header = 'light'
      }
    }
  }

  const [pageReady, setPageReady] = useState(false)
  const [heroReady, setHeroReady] = useState(false)
  const [fontsReady, setFontsReady] = useState(false)
  const [isHeroAnimated, setHeroAnimated] = useState(false)

  const [showNextIndicator, setShowNextIndicator] = useState(false)
  const [isPrevPageTriggerEnabled, enablePrevPageTrigger] = useState(false)

  const { fontsLoaded } = context
  useEffect(() => {
    if (fontsLoaded) setFontsReady(true)
    if (fontsReady && heroReady) onPageReady()
  }, [fontsLoaded, fontsReady, heroReady])

  function onPageReady() {
    if (context.transitionState === 'overscrolled-bottom') {
      dispatch({ type: 'SET_TRANSITION_STATE', state: 'overscrollEnter' })
      window.scrollTo(0, 0)
      setPageReady(true)
    } else {
      if (context.shouldScrollToProjectList) {
        dispatch({ type: 'SET_PAGE_LAYOUT', layout: 'normal' })

        setTimeout(() => {
          window.scrollTo(0, window.innerHeight + 15)
          dispatch({ type: 'SCROLL_TO_PROJECT_LIST', scrollToList: false })
          dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
          setPageReady(true)
        }, 100)
      } else {
        dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
        setPageReady(true)
      }
    }
  }

  function heroAnimated() {
    dispatch({ type: 'SET_PAGE_LAYOUT', layout: 'normal' })
    setShowNextIndicator(true)
    setHeroAnimated(true)
  }

  function scrollToProjectList() {
    const targetScrollY = document.getElementById('project-listing').offsetTop + 10
    const animatable = { scrollY: window.scrollY }
    gsap.to(animatable, {
      scrollY: targetScrollY,
      duration: 0.8,
      ease: Power3.easeOut,
      onUpdate: () => {
        window.scrollTo(0, animatable.scrollY)
      }
    })
  }

  return (
    <>
      <SEO title={page.title} description={page.description} image={page.shareImage} />

      <PrevPageTrigger
        enable={isPrevPageTriggerEnabled}
        show={isHeroAnimated}
        link={props.pageContext.locale === 'sl' ? '/o-nas' : '/en/about-us'}
        title={props.pageContext.locale === 'sl' ? 'O nas' : 'About us'}
      ></PrevPageTrigger>
      <WithPrevPageTrigger onShift={isShifted => enablePrevPageTrigger(isShifted)}>
        <PageHero
          title={page.heroTitle}
          text={page.heroTekst}
          image={page.heroImage}
          fixed
          onImageLoaded={() => setHeroReady(true)}
          onAnimated={heroAnimated}
          startAnimations={pageReady}
        >
          <NextSectionIndicator
            label={props.pageContext.locale === 'sl' ? 'Razišči' : 'Explore'}
            variation="white"
            onClick={scrollToProjectList}
            show={showNextIndicator}
          />
        </PageHero>

        {projectNodes && <ProjectListing projects={projectNodes} />}
        <Footer companyInfo={data.companyInfo} />
      </WithPrevPageTrigger>
    </>
  )
}

export default localize(ProjectsPage)
