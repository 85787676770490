import React, { Component } from 'react'
import PropTypes from 'prop-types'
import arrDown from '../images/arr_down.svg'
import styles from './NextSectionIndicator.module.css'
import gsap from 'gsap'

export default class NextSectionIndicator extends Component {
  static propTypes = {
    nextSection: PropTypes.object,
    prevSection: PropTypes.object,
    changeSection: PropTypes.func,
    onClick: PropTypes.func,
    show: PropTypes.bool,
    variation: PropTypes.oneOf(['white', 'dark']),
    direction: PropTypes.oneOf(['down', 'up']),
    showNextIndicator: PropTypes.bool,
    showPrevIndicator: PropTypes.bool,
    label: PropTypes.string
  }

  constructor(props) {
    super(props)

    this.label = React.createRef()
    this.icon = React.createRef()
    this.enterTween = null
    this.exitTween = null

    this.state = {
      position: 'bottom'
    }
  }

  componentDidMount() {
    if (this.props.show) this.animateIn()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.props.show ? this.animateIn() : this.animateOut()
    }

    if (prevProps.direction !== this.props.direction) {
      this.animateOut()
    }
  }

  /**
   * We receive nextSection and prevSection in props
   * But one of those sections can be undefined if we are either on last section or first section
   * That is why we have to check if we are going down, and are on the last section, we show indicator at the top even though we are going down
   * When we are going up and hit the first section, show indicator at the bottom.
   */
  updatePosition() {
    if (this.props.direction === 'down') {
      if (typeof this.props.nextSection === 'undefined') this.setState({ position: 'top' })
      else this.setState({ position: 'bottom' })
    } else {
      // Currently we disable it for top position
      return
      if (typeof this.props.prevSection === 'undefined') this.setState({ position: 'bottom' })
      else this.setState({ position: 'top' })
    }
  }

  animateIn() {
    if (this.exitTween) this.exitTween.kill()
    if (
      (this.state.position === 'top' && this.props.showPrevIndicator === false) ||
      (this.state.position === 'bottom' && this.props.showNextIndicator === false)
    )
      return false
    const from = this.state.position === 'top' ? { y: -15 } : { y: 15 }
    const to = { opacity: 1, y: 0, stagger: 0.2, delay: 0.4 }
    this.enterTween = gsap.fromTo(
      [this.label.current, this.icon.current],
      { ...from, opacity: 0 },
      to
    )
  }

  animateOut() {
    if (this.enterTween) this.enterTween.kill()
    this.exitTween = gsap.to([this.label.current, this.icon.current], {
      opacity: 0,
      onComplete: this.updatePosition.bind(this)
    })
  }

  onClick() {
    if (this.props.onClick) this.props.onClick()

    if (this.props.changeSection) {
      const { nextSection, prevSection, changeSection } = this.props
      this.state.position === 'bottom'
        ? changeSection(nextSection.index)
        : changeSection(prevSection.index)
    }
  }

  render() {
    return (
      <div
        className={`${styles.root} ${styles[this.state.position]} ${styles[this.props.variation]}`}
        onClick={this.onClick.bind(this)}
        role="button"
      >
        <div className={styles.label} ref={this.label}>
          {this.props.label}
          {this.state.position === 'bottom' &&
            this.props.nextSection &&
            this.props.nextSection.title}
          {this.state.position === 'top' && this.props.prevSection && this.props.prevSection.title}
        </div>
        <div className={styles.icon} ref={this.icon}>
          <img src={arrDown} alt="" />
        </div>
      </div>
    )
  }
}
