import React from 'react'
import PropTypes from 'prop-types'
import styles from './Footer.module.css'
import CompanyDetails from './CompanyDetails'

function Footer({ companyInfo }) {
  return (
    <footer className={styles.footer}>
      <CompanyDetails companyInfo={companyInfo}></CompanyDetails>
    </footer>
  )
}

Footer.propTypes = {
  companyInfo: PropTypes.object.isRequired
}

export default Footer
