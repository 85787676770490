import React, { useRef, useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './PageHero.module.css'
import Img from 'gatsby-image'
import gsap, { Power4, Power2 } from 'gsap'
import { GlobalStateContext } from '../containers/GlobalContextProvider'

const PageHero = ({
  title,
  text,
  image,
  titleVariant,
  children,
  fixed,
  onImageLoaded,
  onAnimated,
  startAnimations,
  loading
}) => {
  const imageLoaded = () => {
    if (onImageLoaded) onImageLoaded()
  }

  const context = useContext(GlobalStateContext)

  const imageRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()

  let titleTween, textTween

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (titleRef.current) {
        titleTween = gsap.fromTo(
          titleRef.current,
          { opacity: 0, y: 60 },
          { opacity: 1, y: 0, duration: 0.8, paused: true }
        )
      }

      if (textRef.current) {
        textTween = gsap.fromTo(
          textRef.current.querySelectorAll('span'),
          { opacity: 0, y: 15 },
          {
            opacity: 1,
            y: 0,
            duration: 1.5,
            delay: 0.5,
            ease: Power4.easeOut,
            paused: true,
            stagger: { amount: 0.5 }
          }
        )
      }
    }

    if (startAnimations) {
      if (titleTween) titleTween.play()
      if (textTween) textTween.play()

      const timeToFinish = textTween ? 1000 : 200
      setTimeout(() => {
        if (onAnimated) onAnimated()
      }, timeToFinish)
    }
  }, [startAnimations])

  const [isFullHeight, setFullHeight] = useState(true)
  useEffect(() => {
    setFullHeight(context.viewport.isFullHeight)
  }, [context.viewport])

  function wrapLines(text) {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 768) return <span>{text}</span>
      else return text.split('\n').map((line, index) => <span key={index}>{line}</span>)
    } else return text
  }

  return (
    <section id="hero" className={`${styles.root}`}>
      <div className={styles.heroInner}>
        {image && (
          <div className={styles.backgroundImage} ref={imageRef}>
            <Img
              loading={loading}
              fluid={image.asset.fluid}
              onLoad={imageLoaded}
              style={{ height: '100%', height: '100vh' }}
            />
          </div>
        )}

        <div className={styles.heroContent}>
          {title && (
            <h1
              className={`${styles.title} ${titleVariant ? styles[titleVariant] : ''}`}
              ref={titleRef}
            >
              {title}
            </h1>
          )}
          {text && (
            <h2 className={styles.text} ref={textRef}>
              {wrapLines(text)}
            </h2>
          )}
        </div>

        <div className={`${styles.bottomContent} ${!isFullHeight ? styles.extraBottom : ''}`}>
          {children}
        </div>
      </div>
    </section>
  )
}

PageHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  titleVariant: PropTypes.string,
  children: PropTypes.element,
  fixed: PropTypes.bool,
  loading: PropTypes.oneOf(['lazy', 'eager']),
  onAnimated: PropTypes.func.isRequired
}

PageHero.defaultProps = {
  loading: 'eager'
}

export default PageHero
